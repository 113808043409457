import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

//styles
import blogStyles from "./blog.module.scss"
import Seo from "../components/Seo/Seo"

export const blogQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        categories
        tags
        images {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      body
      excerpt
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`

export default function Blog({ data, location }) {
  const { mdx: blog } = data
  const image = blog.frontmatter.images
    ? blog.frontmatter.images.childImageSharp.resize
    : null
  return (
    <>
      <Seo
        title={`Garden | ${blog.frontmatter.title}`}
        description={blog.frontmatter.description || blog.excerpt}
        image={image}
        pathname={location.pathname}
        keywords={blog.frontmatter.tags}
      />
      <div className={blogStyles.container}>
        <h1 className={blogStyles.title}>{blog.frontmatter.title}</h1>
        <p className={blogStyles.lastUpdated}>
          Last updated: {blog.parent.modifiedTime}
        </p>
        <MDXRenderer>{blog.body}</MDXRenderer>
      </div>
    </>
  )
}
